import React, { Fragment } from 'react'
import Helmet from 'react-helmet'

export default function Pagina (props) {
  const { pagina } = props
  console.log(pagina)
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt" />
        <title>{`${pagina.metaTitle}`}</title>
        <meta name="title" content={`${pagina.metaTitle}`} />
        <meta name="description" content={pagina.metaDescription} />
        <meta name="keywords" content={pagina.tags.join(', ')} />
        <meta name="author" content="Lwart" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${pagina.metaTitle} | Lwart`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.lwart.com.br${pagina.path}`} />
        <meta
          property="og:image"
          content={`https://www.lwart.com.br${pagina.ogImage.localFile.publicURL}`}
        />
        {/* TODO: Descomentar isso após 30/07/2020
        <meta property="og:image:width" content={pagina.card.file.details.image.width} />
        <meta property="og:image:height" content={pagina.card.file.details.image.height} />
        */}
        <meta property="og:description" content={pagina.metaDescription} />
        <script>
          {`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2958794,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
      </Helmet>
    </Fragment>
  )
}
