import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import iconBanner from '../images/fornecedores/icons/iconFornecedores.svg'
import iconBaixar from '../images/fornecedores/icons/baixar.svg'
import iconClique from '../images/fornecedores/icons/clique.svg'
// import pdf1 from '../content/CODIGO DE CONDUTA FORNECEDORES.pdf'
// import pdf2 from '../content/CONDIÇÕES GERAIS DE CONTRATAÇÃO_GERAL_LWART.pdf'
// import pdf3 from '../content/CONDIÇÕES GERAIS DE CONTRATAÇÃO_COLETA DE OLUC_LWART.pdf'
// import pdf4 from '../content/MANUAL DE SEGURANÇA FINAL.pdf'
// import pdf5 from '../content/Sistema de Gestão de Contratos_CLM.pdf'

import BackgroundImg from 'gatsby-background-image'
import Pagina from '../components/Pagina'

import './styles/fornecedores.scss'

const Fornecedores = ({ data }) => {
  return (
    <>
      <Pagina pagina={data.pagina} />
      <Banner banner={data.paralax.childImageSharp.gatsbyImageData} />
      <BaixarPDF
        img1={data.image1}
        img2={data.image2}
        img3={data.image3}
        img4={data.image4}
        img5={data.image5}
        img6={data.image6}
      />
    </>
  )
}

const Banner = ({ banner }) => {
  const image = getImage(banner)
  const bgImage = convertToBgImage(image)

  return (
    <div>
      {/* Desktop */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-md-flex d-none"
      >
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100 position-relative">
            <div className='vertical-line position-absolute'></div>
            <div className='conteudo-banner d-flex'>
              <div className="col-2 p-0">
                <img src={iconBanner} className='w-90' />
              </div>
              <div className="col-5 p-0">
                <h1 className="text-white">Fornecedores</h1>
                <p className=" text-banner text-white mt-3">Estamos comprometidos com a parceria transparente e eficaz com nossos fornecedores. Agradecemos por contribuir cada vez mais para o nosso sucesso mútuo.</p>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImg>
      {/* Mobile */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-md-none d-flex h-50"
      >
        <div className="container h-100 position-relative d-flex align-items-center">
          <div className='vertical-line position-absolute'></div>
          <div>
            <div className='d-flex align-items-center'>
              <img src={iconBanner} className='w-20 mr-3 ml-4 mt-2' />
            </div>
            <div className='mt-3 ml-4'>
              <h1 className="text-white">Fornecedores</h1>
            </div>
            <div className='mt-2 ml-4'>
              <p className="text-white w-55">Estamos comprometidos com a parceria transparente e eficaz com nossos fornecedores. Agradecemos por contribuir cada vez mais para o nosso sucesso mútuo.</p>
            </div>
          </div>
        </div>
      </BackgroundImg>
    </div>
  )
}

const BaixarPDF = (data) => {
  // const isMobile = useMediaQuery({ maxWidth: 576 })
  return (
    <div className='baixar-pdf'>
      <h1 className='titulo p-0'>Tudo o que você precisa está aqui!</h1>
      <div className='content'>
        <div className='content-item-icon'>
          <GatsbyImage
            image={data.img1.childImageSharp.gatsbyImageData}
            alt=""
            className='image-item' />
          <div className='texto-meio'>
            <p className="h5 titulo-item">Código de Conduta para fornecedores e prestadores de serviços</p>
            <p>O Código de Conduta para Fornecedores e Prestadores de Serviços é um importante documento que tem o papel de orientar sobre as principais diretrizes que norteiam a relação da Lwart com o público externo. A transparência e respeito nas parceria são pilares do desenvolvimento sustentável das atividades da Lwart.</p>
          </div>
          <a href='https://lwart-static-assets-prd.s3.amazonaws.com/BOAS_PRATICAS_FORNECEDORES_ATUALIZADO.pdf' download className='icon-direito'>
            <p className='baixar-pdf-text text-white'>
              BAIXAR PDF
            </p>
            <img className='icon-baixar' src={iconBaixar} alt="Ícone de Baixar" />
          </a>
        </div>
      </div>
      <div className='linha-divisoria'></div>
      <div className='content'>
        <div className='content-item-icon w-100'>
          <GatsbyImage
            image={data.img2.childImageSharp.gatsbyImageData}
            alt=""
            className='image-item' />
          <div className='texto-meio'>
            <p className="h5 titulo-item">Condições gerais de contratação</p>
            <p>Reúne as clásulas e condições gerais para todos os tipos de contratos formalizados com a Lwart, estabelecendo direitos e obrigações para as partes envolvidas.</p>
            <div className='content-horizontal'>
              <a href='https://lwart-static-assets-prd.s3.amazonaws.com/CONDI%C3%87%C3%95ES+GERAIS+DE+CONTRATA%C3%87%C3%83O_GERAL_LWART.pdf' download className='icon-horizontal text-center'>
                <p className='baixar-pdf-contratacao text-white'>
                  Condições gerais de contratação Geral
                </p>
                <img className='icon-baixar-horizontal' src={iconBaixar} alt="Ícone de Baixar" />
              </a>
              <a href='https://lwart-static-assets-prd.s3.amazonaws.com/CONDI%C3%87%C3%95ES+GERAIS+DE+CONTRATA%C3%87%C3%83O_COLETA+DE+OLUC_LWART.pdf' download className='icon-horizontal text-center'>
                <p className='baixar-pdf-contratacao text-white'>
                  Condições gerais de contratação Coleta
                </p>
                <img className='icon-baixar-horizontal' src={iconBaixar} alt="Ícone de Baixar" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='linha-divisoria'></div>
      <div className='content'>
        <div className='content-item-icon'>
          <GatsbyImage
            image={data.img3.childImageSharp.gatsbyImageData}
            alt=""
            className='image-item' />
          <div className='texto-meio'>
            <p className="h5 titulo-item">Manual de segurança para empresas contratadas</p>
            <p>O Manual de Empresa Contratadas trata das regras de saúde e segurança que as empresas contratadas fixas e esporádicas devem seguir ao realizarem atividades na matriz da Lwart Soluções Ambientais, localizada na cidade de Lençóis Paulistas/SP.</p>
          </div>
          <a href='https://lwart-static-assets-prd.s3.amazonaws.com/MANUAL_DE_SEGURAN%C3%87A_REV_05_ajustes.pdf' download className='icon-direito'>
            <p className='baixar-pdf-text text-white'>
              BAIXAR PDF
            </p>
            <img className='icon-baixar' src={iconBaixar} alt="Ícone de Baixar" />
          </a>
        </div>
      </div>
      <div className='linha-divisoria'></div>
      <div className='content'>
        <div className='content-item'>
          <GatsbyImage
            image={data.img4.childImageSharp.gatsbyImageData}
            alt=""
            className='image-item' />
          <div className='texto-meio'>
            <p className="h5 titulo-item">Portal de compras</p>
            <p>O portal de compras (e-procurement) é a plataforma digital que a Lwart utiliza para fazer a gestão dos processos de compras. O portal simplifica a interação entre a Lwart e seus fornecedores por meio da centralização das transações em um único ambiente, desde a cotação até a formalização do pedido de compras.</p>
            <p>Acesso interno ou externo*, através do link: <a href="https://portaldecompraslwart.com" target="_blank" rel="noopener noreferrer" className='color-link'>https://portaldecompraslwart.com</a></p>
            <p className='text-small'>*O acesso externo ao usuário na plataforma e-procurement é concedido às empresas convidadas à participar de processo de cotação.</p>
          </div>
        </div>
      </div>
      <div className='linha-divisoria'></div>
      <div className='linha-divisoria'></div>
      <div className='content'>
        <div className='content-item'>
          <GatsbyImage
            image={data.img5.childImageSharp.gatsbyImageData}
            alt=""
            className='image-item' />
          <div className='texto-meio'>
            <p className=" h5 titulo-item">CLM (Sistema de Gestão de Contratos)</p>
            <p>O CLM é um sistema que visa promover o cadastro e a gestão dos documentos de terceiros (empresas prestadoras de serviços, subcontratadas e seus colaboradores).</p>
            <p className='mb-0'>• Cadastro dos documentos e dados da empresa;</p>
            <p className='mb-0'>• Cadastro dos documentos e dados de terceiros;</p>
            <p>• Atualização da base de terceiros, quando necessário, com exclusão de terceiros previamente alocados ou inserção de novos terceiros.</p>
            <p className=" h5 titulo-item">Como acessar o CLM</p>
            <p>Através do link: <a href="https://www.simplesmenteuse.com.br/lwart" target="_blank" rel="noopener noreferrer" className='color-link'>https://www.simplesmenteuse.com.br/lwart</a><br />Observadas as <a href='https://lwart-static-assets-prd.s3.amazonaws.com/Sistema+de+Gest%C3%A3o+de+Contratos_CLM.pdf' target="_blank" rel="noopener noreferrer" className='color-link'>instruções e passo-a-passo</a>.</p>
          </div>
        </div>
      </div>
      <div className='linha-divisoria'></div>
      <div className='content'>
        <div className='content-item'>
          <GatsbyImage
            image={data.img6.childImageSharp.gatsbyImageData}
            alt=""
            className='image-item' />
          <div className='texto-meio'>
            <p className="h5 titulo-item">Portal Financeiro</p>
            <p>Recurso criado para facilitar o acesso a informações relacionadas aos pagamentos já realizados e projeções e programações futuras (de até 180 dias) dos fornecedores e/ou prestadores de serviços.</p>
            <p className="h5 titulo-item">Como acessar o Portal Financeiro:</p>
            <div className='icon-num-container'>
              <p className='icon-num text-white'>
                1
              </p>
              <p className='descricao-num'>
                Enviar um e-mail parapagar@lwart.com.br informando o CNPJ da empresa que deseja obter o acesso, com identificação e comprovação do representante legal.
              </p>
            </div>
            <div className='icon-num-container'>
              <p className='icon-num text-white'>
                2
              </p>
              <p className='descricao-num'>
                Será criado acesso e, em seguida, será encaminhado um e-mail com as instruções detalhadas de acesso e utilização do Portal Financeiro.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='linha-divisoria'></div>
      <div className='content'>
        <div className='botao'>
          <Link to="/seja-um-fornecedor/" className="btn">
            Quero ser um FORNECEDOR da Lwart
            <img src={iconClique} alt="Ícone de clique" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Fornecedores

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/fornecedores/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "fornecedores/bannerFornecedores.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image1: file(relativePath: {eq: "fornecedores/image-items/item1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image2: file(relativePath: {eq: "fornecedores/image-items/item2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image3: file(relativePath: {eq: "fornecedores/image-items/item3.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image4: file(relativePath: {eq: "fornecedores/image-items/item4.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image5: file(relativePath: {eq: "fornecedores/image-items/item5.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image6: file(relativePath: {eq: "fornecedores/image-items/item6.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
